import React, { useState } from "react"
import getFirebase from '../../utils/firebase'
import Input from "../input/input"
import Button from "../button/button"
import Spinner from "../spinner/spinner"
import RadioButton from "../radioButton/radioButton";
import SocialMediaButtons from "../socialMediaButtons/soclalMediaButtons"
import styles from "./taxes.module.scss"

import * as VALIDATIONS from '../../utils/validations';

const socialMediaItems = [
    {
        id: "fb",
        label: "Facebook",
        url: "https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fevvafinanzas.com",
        amplitudeClickEvent: "PreReferralFacebook"
    },
    {
        id: "tw",
        label: "Twitter",
        url: "https://twitter.com/intent/tweet/?text=Te%20invito%20a%20pre-registrarte%20en%20EVVA%20Finanzas%20y%20podremos%20obtener%20transferencias%20bancarias%20totalmente%20GRATIS%20entre%20nosotros.%20Solo%20escribe%20mi%20nombre%20en%20%22%C2%BFC%C3%B3mo%20nos%20conociste%3F%22%20Yo%20ya%20lo%20hice&url=https%3A%2F%2Fevvafinanzas.com",
        amplitudeClickEvent: "PreReferralTwitter"
    },
    {
        id: "email",
        label: "Email",
        url: "mailto:?subject=Te%20invito%20a%20pre-registrarte%20en%20EVVA%20Finanzas%20y%20podremos%20obtener%20transferencias%20bancarias%20totalmente%20GRATIS%20entre%20nosotros.%20Solo%20escribe%20mi%20nombre%20en%20%22%C2%BFC%C3%B3mo%20nos%20conociste%3F%22%20Yo%20ya%20lo%20hice&amp;body=https%3A%2F%2Fevvafinanzas.com",
        amplitudeClickEvent: "PreReferralEmail"
    },
    {
        id: "in",
        label: "LinkedIn",
        url: "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fevvafinanzas.com&title=Te%20invito%20a%20pre-registrarte%20en%20EVVA%20Finanzas%20y%20podremos%20obtener%20transferencias%20bancarias%20totalmente%20GRATIS%20entre%20nosotros.%20Solo%20escribe%20mi%20nombre%20en%20%22%C2%BFC%C3%B3mo%20nos%20conociste%3F%22%20Yo%20ya%20lo%20hice&summary=Te%20invito%20a%20pre-registrarte%20en%20EVVA%20Finanzas%20y%20podremos%20obtener%20transferencias%20bancarias%20totalmente%20GRATIS%20entre%20nosotros.%20Solo%20escribe%20mi%20nombre%20en%20%22%C2%BFC%C3%B3mo%20nos%20conociste%3F%22%20Yo%20ya%20lo%20hice&source=https%3A%2F%2Fevvafinanzas.com",
        amplitudeClickEvent: "PreReferralLinkedIn"
    },
    {
        id: "wa",
        label: "WhatsApp",
        url: "https://wa.me/?text=Te%20invito%20a%20pre-registrarte%20en%20EVVA%20Finanzas%20y%20podremos%20obtener%20transferencias%20bancarias%20totalmente%20GRATIS%20entre%20nosotros.%20Solo%20escribe%20mi%20nombre%20en%20%22%C2%BFC%C3%B3mo%20nos%20conociste%3F%22%20Yo%20ya%20lo%20hice%20https%3A%2F%2Fevvafinanzas.com",
        amplitudeClickEvent: "PreReferralWhatsapp"
    },
];

const content = {
    es: {
        email: "Correo Electrónico",
        name: "Nombre Completo",
        company: "Empresa",
        radioGroup: [
            {
                id: "Moral",
                label: "Soy Persona Moral (S.A./S.A.P.I/C.V, etc.)",
                value: "Moral"
            },
            {
                id: "Fisica",
                label: "Soy Persona Física con Negocio o Freelancer",
                value: "Fisica"
            }
        ],
        button: "Enviar",
        spinner: "Enviando...",
        formResponse: {
            success: "Tu solicitud fue enviada exitosamente",
            error: "Error al enviar tu solicitud. Por favor intenta mas tarde o verifica tu conexión a internet"
        },
        successMsg: "<h2>¡Muchas Gracias por registrarte! </h2><h3>Muy pronto recibirás información de los siguientes pasos. Mientras tanto si tienes aliados, clientes, o proveedores que estén interesados o que tengan necesidad de este servicio, no dudes en invitarlos..</h3><h4>¿Cómo? <strong>¡Solo invítalos a través de redes sociales!</strong></h4>",
        note: "Al continuar aceptas nuestra <a href='../PC.pdf' target='_blank' rel='noopener noreferrer'>política de privacidad</a> y <a href='../TC.pdf' target='_blank' rel='noopener noreferrer'>términos de uso.</a>"
    },
    en: {
        email: "Email address",
        name: "Full name",
        company: "Company name",
        radioGroup: [
            {
                id: "Moral",
                label: "Limited Company (S.A./S.A.P.I/C.V, etc.)",
            },
            {
                id: "Fisica",
                label: "Sole Proprietorship and Freelancers",
            }
        ],
        button: "Submit",
        spinner: "Sending...",
        formResponse: {
            success: "Your request was submitted successfully",
            error: "Something was wrong sending your request. Please try late or check your internet connection"
        },
        successMsg: "<h2>Thank you very much for registering!</h2><h3>Very soon you will receive information on the following steps. Meanwhile, if you have allies, clients, or suppliers that are interested or have a need of this service, do not hesitate to invite them.</h3><h4>How? <strong>Just invite them through our social media!</strong></h4>",
        note: "By continuing you accept our <a href='../PC.pdf' target='_blank' rel='noopener noreferrer'>privacy policy</a> and <a href='../TC.pdf' target='_blank' rel='noopener noreferrer'>terms of use.</a>"
    }
};

const TaxesForm = ({title, subtitle, lang, amplitude, amplitudeClickEvent}) => {
    const [displayButton, setDisplayButton] = useState(true);
    const [displayMsg, setDisplayMsg] = useState(true);
    const [formResponse, setFormResponse] = useState({});
    const [validations, setValidations] = useState({});
    const [formData, setFormData] = useState({
        EMAIL: "",
        FNAME: "",
        COMPANY: "",
        BTYPE: ""
    });

    const setHTMLContent = (msg) => ({__html: msg});

    const changeInputHandler = (e) => {
        const {name, value} = e.target;

        setFormData({...formData, [name]: value})
    };

    const validateForm = () => {
        const validators = {
            EMAIL: [VALIDATIONS.required, VALIDATIONS.email],
            FNAME: [VALIDATIONS.required, VALIDATIONS.alphaNumericWithSpaces, VALIDATIONS.minLength(3)],
            COMPANY: [VALIDATIONS.required, VALIDATIONS.alphaNumericWithSpaces, VALIDATIONS.minLength(3), VALIDATIONS.maxLength(250)],
            BTYPE: [VALIDATIONS.required]
        };

        return VALIDATIONS.validate(formData, validators);
    };

    const submitHandler = () => {
        const validationErrors = validateForm();
        setValidations(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            if (amplitude && amplitudeClickEvent) {
                amplitude.getInstance().logEvent(amplitudeClickEvent)
            }

            setDisplayButton(false);

            const taxesCreateLead = getFirebase().functions().httpsCallable('taxes-createLead');

            taxesCreateLead(formData).then(result => {
                if(result.data.messageId) {
                    setFormResponse({result: "success"});
                    setDisplayButton(true);
                    const t = window.setTimeout(() => {
                        setDisplayMsg(false);
                        clearTimeout(t);
                    }, 3000);
                }
            })
        }
    };

    return (
        <div>
            {formResponse.result === 'success' ? (
                <div>
                    {displayMsg &&
                    <div className={styles['taxes-form_success']}>
                        {content[lang].formResponse[formResponse.result]}
                    </div>
                    }
                    <div className={styles["taxes-form"]}>
                        <div className={styles["taxes-form_title"]} dangerouslySetInnerHTML={setHTMLContent(content[lang].successMsg)}/>
                        <div className={styles["taxes-form_social_media"]}>
                            <SocialMediaButtons items={socialMediaItems} amplitude={amplitude}/>
                        </div>
                    </div>
                </div>
            ):(
                <div>
                    {formResponse.result === 'error' &&
                    <div className={styles['taxes-form_error']}>
                        {content[lang].formResponse[formResponse.result]}
                    </div>
                    }
                    <div className={styles["taxes-form"]}>
                        {(title || subtitle) &&
                        <div className={styles["taxes-form_title"]}>
                            {title && <h2>{title}</h2>}
                            {subtitle && <h3>{subtitle}</h3>}
                        </div>
                        }
                        <div className={styles["taxes-form_form"]}>
                            <Input name="EMAIL" value={formData.EMAIL} prompt={content[lang].email} onChange={changeInputHandler}/>
                            {validations.EMAIL && <div className={styles["taxes-form_validations"]}>{validations.EMAIL[lang]}</div>}
                            <Input name="FNAME" value={formData.FNAME} prompt={content[lang].name} onChange={changeInputHandler}/>
                            {validations.FNAME && <div className={styles["taxes-form_validations"]}>{validations.FNAME[lang]}</div>}
                            <Input name="COMPANY" value={formData.COMPANY} prompt={content[lang].company} onChange={changeInputHandler}/>
                            {validations.COMPANY && <div className={styles["taxes-form_validations"]}>{validations.COMPANY[lang]}</div>}
                            {content[lang].radioGroup.map((type, index) => (
                                <RadioButton key={type.id} index={index} name='BTYPE' value={type.id} text={type.label} onChange={changeInputHandler}/>
                            ))}
                            {validations.BTYPE && <div className={styles["taxes-form_validations"]}>{validations.BTYPE[lang]}</div>}
                            <div className={styles["taxes-form_action"]}>
                                {displayButton ? (
                                    <Button text={content[lang].button} onClick={() => submitHandler()}/>
                                ):(
                                    <Spinner text={content[lang].spinner} border/>
                                )}
                            </div>
                            <div className={styles["taxes-form_note"]} dangerouslySetInnerHTML={setHTMLContent(content[lang].note)} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default TaxesForm