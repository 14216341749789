import React from "react"
import styles from "./spinner.module.scss"

const Spinner = ({text, size, border}) => {
    const setSize = size || 30;
    const setBorder = size || 30 / 5;

    return (
        <div className={[styles["spinner"], border ? styles["spinner-outlined"] : ""].join(" ")}>
            <div className={styles["spinner-loader"]} style={{width: `${setSize}px`, height: `${setSize}px`, borderWidth: `${Math.round(setBorder)}px` }}/>
            {text && <span>{text}</span>}
        </div>
    )
};

export default Spinner