export const required = value => (value ? undefined : {es: "Requerido", en: "Required"});

export const maxLength = max => value =>
  value && value.length > max ? {es:`Debe contener ${max} caracteres o menos`, en: `Must be ${max} characters or less`} : undefined;

export const minLength = min => value =>
  value && value.length < min ? {es: `Debe contener ${min} caracteres o más`, en: `Must be ${min} characters or more`} : undefined;

export const alphaNumericWithSpaces = value =>
  value && /[^a-zA-Z0-9ñÑåÅæÆøØäÄöÖ\s-]/i.test(value)
    ? {es: "Solo caracteres alfanuméricos", en: "Only alphanumeric characters"}
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? {es: "Correo electrónico no válido", en: "Invalid email address"}
    : undefined;

export const phoneNumber = value =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
        ? {es: "El número de teléfono debe contener 10 digitos", en: "Invalid phone number, must be 10 digits"}
        : undefined;

export const number = value =>
    value && isNaN(Number(value)) ? {es: "Debe ser un número" , en: "Must be a amount" } : undefined;

export const validate = (data, validators) => {
  const errors = {};
  for(const key in validators) {
    for(const validator of validators[key]) {
      const err = validator(data[key]);
      if (typeof err !== 'undefined') {
        errors[key] = err;
      }
    }
  }

  return errors;
};