import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import TaxesForm from "../components/taxesForm/taxesForm"
import Card from "../components/card/card";
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";
const contentMaxWidth = "980px";

const Taxes = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const taxesFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <TaxesForm lang={appState.lang} title="Thank you for your interest!" subtitle="We are about to launch 🚀! Register and a get preferential access as soon as possible." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpSATCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="taxes-sat" title="SME, Freelancer, No more tax stress" description="EVVA provides a simple overview of your Tax Business Information for better control and decision making."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection title="SME, Freelancer, No more tax stress." description="<h3>EVVA provides simple overview of your Tax Business Information for better control and decision making.</h3>" position="left" image="hero-sat-en.png" actionLabel="Request your invitation" action={taxesFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpSAT">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection title="Stay ahead of SAT with EVVA" maxWidth={contentMaxWidth}>
                <Card
                    title="Never miss a deadline again"
                    description="<p>Connect your SAT account with Evva.<br />Get notified whenever you get a new invoice, and tax related reminders.</p>"
                    descriptionColor="#363636"
                    image="deadline-taxes-en.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Keep your tax in control"
                    description="<p>Our calculator estimates how much income tax you owe as you earn.</p>"
                    descriptionColor="#363636"
                    image="income-tax-en.svg"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Take better decision"
                    description="<p>Understand your business with a simple overview of your cash flow and based on your invoices.</p><p>Take better decisions, identify where you are spending the most, optimise and reduce cost.</p>"
                    descriptionColor="#363636"
                    image="better-tax-decision-en.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="40px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection maxColumns={4} title="Introducing EVVA Marketplace" description="We have a set of products and services for your daily business needs.">
                <Card
                    subtitle="Business account"
                    image="logo.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en"
                    linkLabel="Learn more"
                    imageShadow
                />
                <Card
                    subtitle="Evva digital rewards"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en/wallet"
                    linkLabel="Learn more"
                    imageShadow
                />
                <Card
                    subtitle="SME financing & loans"
                    image="logo-uniclick-white.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="/en/financing"
                    linkLabel="Learn more"
                    imageShadow
                />
                <Card
                    subtitle="Secure transactions"
                    image="logo-fido.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_fido"
                    link="https://www.escrow.fido.mx/"
                    linkLabel="Learn more"
                    imageShadow
                />
            </PageSection>
            <SecondarySection
                title="Together we grow"
                shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
                position="center"
                image="evva-together.jpg"
                actionLabel="Request your invitation"
                action={taxesFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpSAT"
            />
        </Layout>
    )
};

export default Taxes