import React from "react"
import styles from "./radioButton.module.scss"

const RadioButton = ({index, name, text, value, onChange}) => {
  return (
    <div className={styles["radio-button"]}>
      <label htmlFor={`${index}_${name}`}>
        <input id={`${index}_${name}`} type='radio' name={name} value={value} onChange={e => onChange(e)}/>
        <div className={styles["radio-button_circle"]} />
        {text}
      </label>
    </div>
  )
};

export default RadioButton