import React from "react"
import Icon from "../icon/icon"
import styles from "./socialMediaButtons.module.scss"

const SocialMediaButtons = ({items, amplitude}) => {

  const amplitudeHandler = (event) => {
    if(amplitude && event) {
      amplitude.getInstance().logEvent(event)
    }
  };

  return (
    <div className={styles["social-media"]}>
      <ul>
        {items.map(item => (
          <li key={item.id} className={styles[`social-media_${item.id}`]}>
            <a onClick={() => amplitudeHandler(item.amplitudeClickEvent)} href={item.url} target="_blank" rel="noopener noreferrer"><span><Icon icon={`${item.id}.svg`} size="15px"/></span>{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default SocialMediaButtons

